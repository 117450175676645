<template>
    <div class="margin-bottom-30">
        <teleport to="#detail-menu-teleport" v-if="isMounted && isCurrenPage">
            <div class="anchor-box">
                <div
                    v-for="p in this.pages"
                    :key="p.key"
                    v-text="p.label"
                    :class="{ active: page === p.key }"
                    @click="scrollToPage(p.key)"
                />
            </div>
        </teleport>
        <div
            class="content-viewtitle"
            :class="{ active: isToggle }"
            @click="isToggle = $isMobile() ? true : !isToggle"
        >
            佣金明細
            <div class="info-icon" tooltip="佣金明細文字放在此" tooltip-touch />
            <div class="toggle-menu-icon" />
        </div>
        <transition name="dropdown" mode="out-in">
            <div class="commission-container" v-show="isToggle">
                <div
                    v-for="p in this.pages"
                    :key="p.key"
                    :ref="p.key"
                    :data-page="p.key"
                >
                    <div class="subtitle" v-text="p.label" />
                    <div class="hr" />
                    <Table
                        :isLoading="isLoading"
                        :columnConfig="columnConfig[p.key]"
                        :rows="tableShowData[p.key]"
                        emptyMessage="查無任何結果"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import smoothscroll from 'smoothscroll-polyfill'
import Table from '@/components/Table.vue'
import { getTiaInsuranceCommissionDetailAPI } from '@/assets/javascripts/api'
import {
    insuranceCommissionBonusColumns,
    insuranceServiceAllowanceColumns,
    insuranceYearEndBonusColumns,
    insuranceContinuedBonusColumns
} from '@/assets/javascripts/tia/setting'

const headerOffset = 120

export default {
    name: 'InsuranceCommission',
    components: {
        Table
    },
    props: {
        insId: {
            type: String,
            default: ''
        },
        isCurrenPage: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        scrollToPage: function (pageName) {
            // use polyfill to fix safari not scroll smooth
            smoothscroll.polyfill()
            const page = this.$refs[pageName]
            const pageTop = page.offsetTop
            const offsetPosition = pageTop - headerOffset

            // scrollIntoView 在iOS上不吃scroll-margin，故使用scrollTo
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            })
        },
        registerObserver: function () {
            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.page = entry.target.dataset?.page
                    }
                })
            }
            const option = {
                rootMargin: '-50% 0% -50% 0%',
                threshold: 0
            }
            this.observer = new IntersectionObserver(callback, option)
            this.pages.forEach((p) => {
                const page = this.$refs[p.key]
                this.observer.observe(page)
            })
        },
        transformData: function (rows) {
            return rows?.map((row) => {
                row.kRate = {
                    value: row.kRate,
                    unit: row.kRateUnit
                }
                row.releaseRate = {
                    value: row.releaseRate,
                    unit: row.releaseRateUnit
                }
                return row
            })
        },
        getInsuranceCommissionDetail: async function () {
            try {
                this.isLoading = true
                const res = await getTiaInsuranceCommissionDetailAPI(this.insId)
                this.listData = res.data
            } catch (error) {
                this.$showErrorMessage('查詢佣金明細時發生錯誤')
            } finally {
                this.isLoading = false
            }
        }
    },
    computed: {
        tableShowData: function () {
            let dataset = _.cloneDeep(this.listData)
            Object.keys(dataset).forEach((bonus) => {
                dataset[bonus] = this.transformData(dataset[bonus])
            })
            return dataset
        }
    },
    data() {
        return {
            page: 'commissionBonus',
            pages: [
                { key: 'commissionBonus', label: '一般佣金' },
                { key: 'serviceAllowance', label: '服務津貼' },
                { key: 'yearEndBonus', label: '年終獎金' },
                { key: 'continuedBonus', label: '繼續率獎金' }
            ],
            columnConfig: {
                commissionBonus: insuranceCommissionBonusColumns,
                serviceAllowance: insuranceServiceAllowanceColumns,
                yearEndBonus: insuranceYearEndBonusColumns,
                continuedBonus: insuranceContinuedBonusColumns
            },
            listData: {},
            isLoading: false,
            isMounted: false,
            isToggle: true,
            observer: null
        }
    },
    created() {
        this.getInsuranceCommissionDetail()
    },
    mounted() {
        this.isMounted = true
        this.registerObserver()
    },
    unmounted() {
        this.observer.disconnect()
        this.observer = null
    }
}
</script>

<style lang="scss" scoped>
.content-viewtitle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: margin 1s ease-in-out;
    color: $sixth-black;
    font-size: 24px;
    cursor: pointer;

    @media screen and (max-width: 576px) {
        font-size: 20px;
    }

    .info-icon {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }

    .toggle-menu-icon {
        height: 10px;
        width: 10px;
        margin-left: 15px;
        transition: transform 0.3s;
        transform: rotate(0deg);
        cursor: pointer;

        @media screen and (max-width: 576px) {
            display: none;
        }
    }

    &.active {
        .toggle-menu-icon {
            transform: rotate(90deg);
        }
    }
}

.subtitle {
    color: $sixth-black;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.hr {
    margin-bottom: 10px;
    @media screen and (max-width: 576px) {
        display: none;
    }
}

.anchor-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;

    div {
        width: 100%;
        border: 1px solid $fourth-blue;
        border-left: none;
        padding: 7px 0px;
        color: $fourth-blue;
        font-size: 12px;
        text-align: center;
        cursor: pointer;

        &:first-of-type {
            border-left: 1px solid $fourth-blue;
            border-radius: 3px 0 0 3px;
        }
        &:last-of-type {
            border-radius: 0 3px 3px 0;
        }

        &.active {
            background-color: $fourth-blue;
            color: $primary-white;
        }
    }
}

.commission-container {
    @media screen and (max-width: 576px) {
        display: block !important;
    }
}

.table-wrapper {
    :deep(.table-container:not(.card)) {
        margin-bottom: 20px;
        .row-container .row {
            padding: 0px;
        }
        .bonus-name {
            font-size: 12px;
        }
    }

    :deep(.table) {
        max-height: none;

        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
        }
    }

    :deep(.nodata-container) {
        margin: 0;

        .empty-result-icon {
            display: none;
        }

        .empty-message {
            text-align: left;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    :deep(.card) {
        @media screen and (max-width: 576px) {
            .row-container .row.body {
                .cell {
                    &.bonus-name {
                        order: -10;
                        width: 50%;
                        justify-content: flex-start;
                        font-weight: 600;

                        &:before {
                            display: none;
                        }
                    }
                    &.product-code {
                        order: -9;
                        width: 50%;
                        justify-content: flex-end;
                        font-weight: 600;

                        &:before {
                            display: none;
                        }
                    }
                    &.take-name {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;
                    }
                    &.payment-count {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;
                    }
                }
            }
        }
    }
}
</style>
